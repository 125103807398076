import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CourseDetail } from './CourseDetail';
import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import {BrowserView, MobileView} from 'react-device-detect';

export class Introduction extends Component {
    static displayName = "Giới thiệu"

    render() {
        return (
            <div>
                <div class="separator">
                <div class="line"></div>
                <h1 className='headerOfPage'>Giới thiệu chung</h1>
                <div class="line"></div>
                </div>
                <br />
                <br />
                <Container>
                <BrowserView>
                    <Row>
                        <Col>
                        <img className='courseDetail' src='./assets/images/learner-lesson.jpg' />
                        </Col>
                        <Col>
                            <br />
                            <br />
                            <h3>Trung tâm Ngoại Ngữ Huỳnh Khoa English được thành lập tháng 09/2016 là một trong những địa chỉ luyện thi TOEIC, Anh Văn Giao Tiếp uy tín & chất lượng tại Thành Phố Hồ Chí Minh </h3>
                            <h3>Với đội ngũ giáo viên nhiệt huyết, tận tâm, dày dặn kinh nghiệm cùng phương châm đào tạo “Thầy/Cô tận tâm, Trò học hết mình”</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br />
                            <br />
                            <h3>Trung tâm đã giúp hàng nghìn sinh viên đạt mục tiêu ra trường, trong đó có hàng trăm học viên đạt kết quả vượt kỳ vọng với mức điểm 800+, 900+ TOEIC.</h3>
                            <h3>Địa chỉ: 369 Nguyễn Thị Đặng, Phường Tân Thới Hiệp, Quận 12</h3>
                            <h3>Hotline: 0901.457.122 or 0901.369.122</h3>
                        </Col>
                        <Col>
                        <img className='courseDetail' src='./assets/images/Toic650-1.jpg' />
                        </Col>
                    </Row>
                    </BrowserView>
                    <MobileView>
                        <Row>
                            <Col>
                            <img className='courseDetail' src='./assets/images/learner-lesson.jpg' />
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col>
                                <br />
                                <br />
                                <h3>Trung tâm Ngoại Ngữ Huỳnh Khoa English được thành lập tháng 09/2016 là một trong những địa chỉ luyện thi TOEIC, Anh Văn Giao Tiếp uy tín & chất lượng tại Thành Phố Hồ Chí Minh </h3>
                                <h3>Với đội ngũ giáo viên nhiệt huyết, tận tâm, dày dặn kinh nghiệm cùng phương châm đào tạo “Thầy/Cô tận tâm, Trò học hết mình”</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className='courseDetail' src='./assets/images/Toic650-1.jpg' />
                            </Col>
                        </Row>
 
                        <Row>
                            <Col>
                                <br />
                                <br />
                                <h3>Trung tâm đã giúp hàng nghìn sinh viên đạt mục tiêu ra trường, trong đó có hàng trăm học viên đạt kết quả vượt kỳ vọng với mức điểm 800+, 900+ TOEIC.</h3>
                                <h3>Địa chỉ: 369 Nguyễn Thị Đặng, Phường Tân Thới Hiệp, Quận 12</h3>
                                <h3>Hotline: 0901.457.122 or 0901.369.122</h3>
                            </Col>
                        </Row>
                    </MobileView>
                </Container>
                <br />
                <br />
                <div class="separator">
            <div class="line"></div>
            <h1 className='headerOfPage'>Lí do chọn Huỳnh Khoa English</h1>
            <div class="line"></div>
            </div>
        <br />
        <br />
        <Table>
                    <tbody>
                        <tr>
                            <td style={{width: "250px"}}><h5>KINH NGHIỆM</h5></td>
                            <td>Trải qua hơn 6 năm hoạt động, Huỳnh Khoa English đã đào tạo hơn 2600 học viên, trong đó hàng trăm bạn đạt 800+, 900+ 95% học viên đạt mục tiêu chỉ sau 1 khóa học</td>
                        </tr>
                        <tr>
                            <td><h5>ĐỘI NGŨ GIÁO VIÊN</h5></td>
                            <td>Huỳnh Khoa English tự hào có đội ngũ giáo viên trẻ, nhiệt huyết, nhiều kinh nghiệm giảng dạy đạt ít nhất 900+ TOEIC trở lên.</td>
                        </tr>
                        <tr>
                            <td><h5>CAM KẾT ĐẦU RA</h5></td>
                            <td>Trung tâm có cam kết đầu ra bằng văn bản. Học lại miễn phí đến khi đạt đầu ra.</td>
                        </tr>
                        <tr>
                            <td><h5>LỘ TRÌNH HỌC TẬP</h5></td>
                            <td>Lộ trình học tập tinh gọn, tối ưu hóa từng kỹ năng, giúp tiết kiệm 90% thời gian tự học.</td>
                        </tr>
                        <tr>
                            <td><h5>TÀI LIỆU KHÓA HỌC</h5></td>
                            <td>Tài liệu được biên soạn chi tiết, rõ ràng theo từng khóa học, phù hợp với từng trình độ.</td>
                        </tr>
                    </tbody>
                </Table>

                <br />
                <div class="separator">
                <div class="line"></div>
                <h1 className='headerOfPage'>Các khóa học tại Huỳnh Khoa English</h1>
                <div class="line"></div>
                    </div>
                <br />
                <br />
                <Container>
                    <Row>
                        <Col>
                        <CourseDetail name="TOEIC Cấp Tốc 550+" url='/toeic-400' imagePath='./assets/images/learner-lesson.jpg' />
                        </Col>
                        <Col>
                            <CourseDetail name="TOEIC Nâng cao 650+" url='/toeic-400' imagePath='./assets/images/Toic650-1.jpg'/>
                        </Col>
                        <Col>
                            <CourseDetail name="Anh Văn Giao Tiếp" url='/toeic-400' imagePath='./assets/images/Communication.jpg'/>
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}

function Example() {
    const [count, setCount] = useState(0);
  
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      // Update the document title using the browser API
      document.title = `You clicked ${count} times`;
    });
  
    return (
      <div>
        <p>You clicked {count} times</p>
        <button onClick={() => setCount(count + 1)}>
          Click me
        </button>
      </div>
    );
  }