import React, { Component } from 'react';
import { SlideShowMain } from './SlideShowMain';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CourseDetail } from './CourseDetail';
import Table from 'react-bootstrap/Table';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <SlideShowMain />
        <br />
        <div class="separator">
            <div class="line"></div>
            <h1 className='headerOfPage'>Lí do chọn Huỳnh Khoa English</h1>
            <div class="line"></div>
            </div>
        <br />
        <br />
        <Table>
                    <tbody>
                        <tr>
                            <td style={{width: "250px"}}><h5>KINH NGHIỆM</h5></td>
                            <td>Trải qua hơn 6 năm hoạt động, Huỳnh Khoa English đã đào tạo hơn 2600 học viên, trong đó hàng trăm bạn đạt 800+, 900+ 95% học viên đạt mục tiêu chỉ sau 1 khóa học</td>
                        </tr>
                        <tr>
                            <td><h5>ĐỘI NGŨ GIÁO VIÊN</h5></td>
                            <td>Huỳnh Khoa English tự hào có đội ngũ giáo viên trẻ, nhiệt huyết, nhiều kinh nghiệm giảng dạy đạt ít nhất 900+ TOEIC trở lên.</td>
                        </tr>
                        <tr>
                            <td><h5>CAM KẾT ĐẦU RA</h5></td>
                            <td>Trung tâm có cam kết đầu ra bằng văn bản. Học lại miễn phí đến khi đạt đầu ra.</td>
                        </tr>
                        <tr>
                            <td><h5>LỘ TRÌNH HỌC TẬP</h5></td>
                            <td>Lộ trình học tập tinh gọn, tối ưu hóa từng kỹ năng, giúp tiết kiệm 90% thời gian tự học.</td>
                        </tr>
                        <tr>
                            <td><h5>TÀI LIỆU KHÓA HỌC</h5></td>
                            <td>Tài liệu được biên soạn chi tiết, rõ ràng theo từng khóa học, phù hợp với từng trình độ.</td>
                        </tr>
                    </tbody>
                </Table>
                <br />
                 <br />
        <div class="separator">
            <div class="line"></div>
            <h1 className='headerOfPage'>Các khóa học tại Huỳnh Khoa English</h1>
            <div class="line"></div>
            </div>
        <br />
        <br />
        <Container>
            <Row>
                <Col>
                   <CourseDetail name="TOEIC Cấp Tốc 550+" url='/toeic-550' imagePath='./assets/images/learner-lesson.jpg' />
                </Col>
                <Col>
                    <CourseDetail name="TOEIC Nâng cao 650+" url='/toeic-650' imagePath='./assets/images/Toic650-1.jpg'/>
                </Col>
                <Col>
                    <CourseDetail name="Anh Văn Giao Tiếp" url='/communication' imagePath='./assets/images/Communication.jpg'/>
                </Col>
            </Row>
        </Container>
        <br />
      </div>
    );
  }
}
