import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CourseDetail } from './CourseDetail';
import { SlideShowSchedule } from './SlideShowSchedule';

export class Course extends Component {
    static displayName = "Khóa học"

    render() {
        return (
            <div>
                <SlideShowSchedule />
                <br />
                <div class="separator">
            <div class="line"></div>
            <h1 className='headerOfPage'>Các khóa học tại Huỳnh Khoa English</h1>
            <div class="line"></div>
            </div>
            <br />
                <br />
                <Container>
                    <Row>
                        <Col>
                            <CourseDetail name="TOEIC Cấp Tốc 550+" url='/toeic-550' imagePath='./assets/images/learner-lesson.jpg' />
                        </Col>
                        <Col>
                            <CourseDetail name="TOEIC Nâng cao 650+" url='/toeic-650' imagePath='./assets/images/Toic650-1.jpg'/>
                        </Col>
                        <Col>
                            <CourseDetail name="Anh Văn Giao Tiếp" url='/communication' imagePath='./assets/images/Communication.jpg'/>
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
            </div>
        );
    }
}