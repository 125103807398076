import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { CourseDetail } from './CourseDetail';

export class Toeic550 extends Component {
    static displayName = "Toeic 550"

    render() {
        return (
            <div>
                <div class="separator">
                    <div class="line"></div>
                    <h1 className='headerOfPage'>Toeic 550+</h1>
                    <div class="line"></div>
                </div>
                <br />
                <h1>Đối tượng tham gia Toeic 550+</h1>
                <ul>
                    <li>Dành cho các Học Sinh, Sinh Viên, Người Đi Làm chưa từng học TOEIC hoặc đã thi TOEIC đạt mức điểm thấp hơn 450</li>
                    <li>Đối tượng có từ vựng và khả năng nghe yếu, không nghe được các câu cơ bản. Đồng thời mất gốc có thể học lớp này với điều kiện dành 1.5-2h/mỗi ngày</li>
                </ul>

                <div class="separator">
                    <div class="line"></div>
                    <h1 className='headerOfPage'>Chi tiết khóa học 550+</h1>
                    <div class="line"></div>
                </div>
                <br />
                <Table>
                    <tbody>
                        <tr>
                            <td>1. 44 phiên âm quốc tế IPA</td>
                        </tr>
                        <tr>
                            <td>2. Luyện mẹo Listening Part 1,2,3,4 đạt điểm tối đa</td>
                        </tr>
                        <tr>
                            <td>3. Kĩ thuật xử lý part 5,6 ít cực nhanh</td>
                        </tr>
                        <tr>
                            <td>4. Các thủ thuật đọc nhanh- suy luận tìm manh mối của những câu hỏi trong bài thi</td>
                        </tr>
                        <tr>
                            <td>5. Part 7 – rút ngắn thời gian làm bài mà hiệu quả.</td>
                        </tr>
                        <tr>
                            <td>6. Nhấn mạnh chủ điểm ngữ pháp trọng tâm trong bài thi</td>
                        </tr>
                        <tr>
                            <td>7. Liệt kê các từ vựng hay gặp trong phần Listening & Reading</td>
                        </tr>
                        <tr>
                            <td>8. Khả năng Skimming + Scanning đơn giản nhất</td>
                        </tr>
                        <tr>
                            <td>9. Thi thử khi kết thúc khóa đề thị sát đề thi thật</td>
                        </tr>
                    </tbody>
                </Table>

                <div class="separator">
            <div class="line"></div>
            <h1 className='headerOfPage'>Các khóa học tại Huỳnh Khoa English</h1>
            <div class="line"></div>
            </div>
            <br />
                <br />
                <Container>
                    <Row>
                        <Col>
                            <CourseDetail name="TOEIC Cấp Tốc 550+" url='/toeic-550' imagePath='./assets/images/learner-lesson.jpg' />
                        </Col>
                        <Col>
                            <CourseDetail name="TOEIC Nâng cao 650+" url='/toeic-650' imagePath='./assets/images/Toic650-1.jpg'/>
                        </Col>
                        <Col>
                            <CourseDetail name="Anh Văn Giao Tiếp" url='/communication' imagePath='./assets/images/Communication.jpg'/>
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
                {/* <img src='./assets/images/Toic650.jpg' width='400px'/>
                <Container>
                    <Row>
                        <Col>
                            <img src='./assets/images/Toic650.jpg' width='400px'/>
                        </Col>
                        <Col>
                            <h1 className='headerOfPage'>Toeic 550+</h1>
                        </Col>
                    </Row>
                </Container> */}
            </div>
        );
    }
}