import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Counter } from "./components/Counter";
import { Course } from './components/Course';
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Introduction } from './components/Introduction';
import { StudySchedule } from './components/StudySchedule';
import { Toeic550 } from './components/Toeic550';
import { Toeic650 } from './components/Toeic650';
import { Communication } from './components/Communication';
import { Document } from './components/Document';

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/introduction',
    element: <Introduction />
  },
  {
    path: '/course',
    element: <Course />
  },
  {
    path: '/study-schedule',
    element: <StudySchedule />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path: '/toeic-550',
    element: <Toeic550 />
  },
  {
    path: '/toeic-650',
    element: <Toeic650 />
  },
  {
    path: '/communication',
    element: <Communication />
  },
  {
    path: '/document',
    element: <Document />
  },
  ...ApiAuthorzationRoutes
];

export default AppRoutes;
