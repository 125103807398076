import React, { Component } from 'react';
import { SlideShowSchedule } from './SlideShowSchedule';
import Table from 'react-bootstrap/Table';

export class StudySchedule extends Component {
    static displayName = "Lịch học"

    render() {
        return (
            <div>
                <SlideShowSchedule />
                <br />
                <div class="separator">
                    <div class="line"></div>
                    <h1 className='headerOfPage'>Lịch khai giảng</h1>
                    <div class="line"></div>
                    </div>
                <br />
                <Table>
                    <thead>
                        <tr>
                        <th className='headerOfTable'>Khóa học</th>
                        <th>Ngày khai giảng</th>
                        <th>Chuẩn đầu vào</th>
                        <th>Chuẩn đầu ra</th>
                        <th>Số lượng</th>
                        <th>Thời lượng</th>
                        <th>Học phí</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Toeic Cấp Tốc 550+</td>
                            <td>Thường xuyên</td>
                            <td>0 - 300</td>
                            <td>550+</td>
                            <td>3 buổi / tuần</td>
                            <td>8 tuần</td>
                            <td>899k/12 buổi</td>
                        </tr>
                        <tr>
                            <td>Toeic Nâng Cao 650</td>
                            <td>Thường xuyên</td>
                            <td>0 - 300</td>
                            <td>650+</td>
                            <td>3 buổi / tuần</td>
                            <td>8 tuần</td>
                            <td>899k/12 buổi</td>
                        </tr>
                        <tr>
                            <td>Anh văn giao tiếp</td>
                            <td>Thường xuyên</td>
                            <td>0 - 300</td>
                            <td>Nghe nói lưu loát</td>
                            <td>3 buổi / tuần</td>
                            <td>8 tuần</td>
                            <td>899k/12 buổi</td>
                        </tr>
                    </tbody>
                </Table>
                <br />
            </div>
        );
    }
}