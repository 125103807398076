import React, { Component } from 'react';
import 'react-slideshow-image/dist/styles.css';
import { Slide, Fade } from 'react-slideshow-image';

const slideImages = [
    {
      url: '/assets/images/BrightEnglishCommunication.png',
      caption: 'Slide 1'
    },
    {
      url: '/assets/images/Output.png',
      caption: 'Slide 2'
    },
    {
      url: '/assets/images/CreateEnv.png',
      caption: 'Slide 3'
    },
  ];
  

export class SlideShowSchedule extends Component {
    static displayName = "Lịch học"

    render() {
        return (
            <div className="slide-container">
      <Fade>
        {slideImages.map((fadeImage, index) => (
          <div className="each-fade" key={index}>
            <div>
              <img class='imageSlide' src={fadeImage.url}/>
            </div>
          </div>
        ))}
      </Fade>
    </div>
          )
    }
}