import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import { NavDropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };

    this.redirectToCourse = this.redirectToCourse.bind(this);

  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  redirectToCourse() {
    //this.navigate("/course");
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <div>
            <a href="#" class="navbar-brand">
                  <img src="../assets/images/logo.png" height="100" alt="CoolBrand" />
            </a>
          </div>
          {/* <NavbarBrand tag={Link} to="/"><h3 className='title'>Huỳnh Khoa English</h3></NavbarBrand> */}
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/introduction">Giới thiệu</NavLink>
              </NavItem>
              <NavItem>
                {/* <NavLink tag={Link} className="text-dark" to="/course">Khóa học</NavLink> */}
                <NavDropdown tag={Link} title="Khóa học" id="basic-nav-dropdown" to="/course" href="/course">
                  <NavDropdown.Item href="/course">Tất cả khóa học</NavDropdown.Item>
                  <NavDropdown.Item href="/toeic-550">Toeic Cấp Tốc 550+</NavDropdown.Item>
                  <NavDropdown.Item href="/toeic-650">
                    Toeic Nâng Cao 650+
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/communication">Anh Văn Giao Tiếp</NavDropdown.Item>
                  {/* <NavDropdown.Divider /> */}
                </NavDropdown>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/study-schedule">Lịch khai giảng</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/document">Tài liệu</NavLink>
              </NavItem>

              {/* <LoginMenu>
              </LoginMenu> */}
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
