import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export class Footer extends Component {
    static displayName = "Footer"

    render() {
        return (
            <Container className='footerBackground'>
                <Row>
                    <br />
                </Row>
                <Row>
                    <Col>
                        <div className='footerImage'>
                            <a href="#" class="navbar-brand">
                                <img src="../assets/images/logo.png" height="150" alt="CoolBrand" />
                            </a>
                        </div>
                        <h3 className='footerTitle' >Huỳnh Khoa English</h3>
                    </Col>
                    <Col xs={6}>
                        <h1>Liên hệ Huỳnh Khoa English</h1>
                        <div>Hotline: <h4>0901.457.122 or 0901.369.122</h4></div>
                        <div>Fanpage: <a href="https://www.facebook.com/KhoaHuynhENGLISH">Fanpage Huỳnh Khoa English</a></div>
                        <div>Website Học Online: <a href="https://huynhkhoa-english.com">Website Huỳnh Khoa English</a></div>
                        <div>Website Thông Tin: <a href="https://huynhkhoa-english.com">Website thông tin Huỳnh Khoa English</a>
                        </div>
                        <div>Địa chỉ: <b>369 Nguyễn Thị Đặng, Phường Tân Thới Hiệp, Quận 12</b>
                        </div>
                    </Col>
                    <Col>
                        <h3>Fanpage Facebook </h3>
                        <a href="https://www.facebook.com/KhoaHuynhENGLISH">Huỳnh Khoa English</a>
                        <h3>Tiktok </h3>
                        <a href="https://www.tiktok.com/@khoahuynhtoeic">Huỳnh Khoa Tiktok</a>
                    </Col>
                </Row>
            </Container>
        );
    }
}