import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { CourseDetail } from './CourseDetail';

export class Toeic650 extends Component {
    static displayName = "Toeic 650"

    render() {
        return (
            <div>
                <div class="separator">
                    <div class="line"></div>
                    <h1 className='headerOfPage'>Toeic 650+</h1>
                    <div class="line"></div>
                </div>
                <br />
                <h1>Đối tượng tham gia Toeic 650+</h1>
                <ul>
                    <li>Dành cho các bạn đã học lớp Toeic Cấp Tốc hoặc các bạn đã học và thi TOEIC đạt số điểm tối thiểu là 500 điểm</li>
                </ul>

                <div class="separator">
                    <div class="line"></div>
                    <h1 className='headerOfPage'>Chi tiết khóa học 650+</h1>
                    <div class="line"></div>
                </div>
                <br />
                <Table>
                    <tbody>
                        <tr>
                            <td>1. Luyện khả năng nghe từ cơ bản đến nâng cao, bắt từ khóa các part 1,2,3,4 trong phần Listening</td>
                        </tr>
                        <tr>
                            <td>2. Sửa chi tiết các bộ đề phần Listening  & Reading</td>
                        </tr>
                        <tr>
                            <td>3. Hướng dẫn kỹ năng xử lý nhanh part 5,6</td>
                        </tr>
                        <tr>
                            <td>4. Hướng dẫn kĩ năng Skimming + Scanning từ cơ bản đến nâng cao trong part 7</td>
                        </tr>
                        <tr>
                            <td>5. Hướng dẫn  năng quản lý thời gian bài thi</td>
                        </tr>
                        <tr>
                            <td>6. Hệ thống từ vựng sau mỗi đề</td>
                        </tr>
                        <tr>
                            <td>7. Thi thử cuối tháng với độ khó tương đương với bài thi thật, giúp học viên đánh giá sát nhất năng lực bản thân</td>
                        </tr>
                    </tbody>
                </Table>

                <div class="separator">
            <div class="line"></div>
            <h1 className='headerOfPage'>Các khóa học tại Huỳnh Khoa English</h1>
            <div class="line"></div>
            </div>
            <br />
                <br />
                <Container>
                    <Row>
                        <Col>
                            <CourseDetail name="TOEIC Cấp Tốc 650+" url='/toeic-650' imagePath='./assets/images/learner-lesson.jpg' />
                        </Col>
                        <Col>
                            <CourseDetail name="TOEIC Nâng cao 650+" url='/toeic-650' imagePath='./assets/images/Toic650-1.jpg'/>
                        </Col>
                        <Col>
                            <CourseDetail name="Anh Văn Giao Tiếp" url='/communication' imagePath='./assets/images/Communication.jpg'/>
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
                {/* <img src='./assets/images/Toic650.jpg' width='400px'/>
                <Container>
                    <Row>
                        <Col>
                            <img src='./assets/images/Toic650.jpg' width='400px'/>
                        </Col>
                        <Col>
                            <h1 className='headerOfPage'>Toeic 650+</h1>
                        </Col>
                    </Row>
                </Container> */}
            </div>
        );
    }
}