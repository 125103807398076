import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { CourseDetail } from './CourseDetail';

export class Communication extends Component {
    static displayName = "Toeic 550"

    render() {
        return (
            <div>
                <div class="separator">
                    <div class="line"></div>
                    <h1 className='headerOfPage'>Tiếng anh giao tiếp mỗi ngày</h1>
                    <div class="line"></div>
                </div>
                <br />
                <h1>Đối tượng tham gia</h1>
                <ul>
                    <li>Dành cho các Học Sinh, Sinh Viên, Người Đi Làm phát triển kĩ năng giao tiếp mỗi ngày</li>
                </ul>

                <div class="separator">
                    <div class="line"></div>
                    <h1 className='headerOfPage'>Chi tiết khóa học Tiếng anh giao tiếp</h1>
                    <div class="line"></div>
                </div>
                <br />
                <Table>
                    <tbody>
                        <tr>
                            <td>1. Phát âm: Hướng dẫn 44 phiên âm quốc tế IPA & Khẩu hình miệng (âm vị học)</td>
                        </tr>
                        <tr>
                            <td>2. Từ vựng: Từ vựng 36 chủ đề trong tình huống giao tiếp</td>
                        </tr>
                        <tr>
                            <td>3. Ngữ Pháp: Cách hình thành câu, đọc & dịch văn bản, Khái niệm cơ bản về danh từ, động từ, tính từ và trạng từ</td>
                        </tr>
                        <tr>
                            <td>4. Nghe: Luyện nghe các chủ đề thường gặp bằng phương pháp Shadowing</td>
                        </tr>
                        <tr>
                            <td>5. Đọc: Luyện đọc qua các bài báo, đoạn văn </td>
                        </tr>
                        <tr>
                            <td>6. Nói: Tự tin giới thiệu bản thân, các chủ đề đời sống giao tiếp, hoạt động ngoại khóa cùng giáo viên nước ngoài</td>
                        </tr>
                        <tr>
                            <td>7. Viết: Viết câu hoàn chỉnh đúng ngữ pháp</td>
                        </tr>
                    </tbody>
                </Table>

                <div class="separator">
            <div class="line"></div>
            <h1 className='headerOfPage'>Các khóa học tại Huỳnh Khoa English</h1>
            <div class="line"></div>
            </div>
            <br />
                <br />
                <Container>
                    <Row>
                        <Col>
                            <CourseDetail name="TOEIC Cấp Tốc 550+" url='/toeic-550' imagePath='./assets/images/learner-lesson.jpg' />
                        </Col>
                        <Col>
                            <CourseDetail name="TOEIC Nâng cao 650+" url='/toeic-650' imagePath='./assets/images/Toic650-1.jpg'/>
                        </Col>
                        <Col>
                            <CourseDetail name="Anh Văn Giao Tiếp" url='/communication' imagePath='./assets/images/Communication.jpg'/>
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
                {/* <img src='./assets/images/Toic650.jpg' width='400px'/>
                <Container>
                    <Row>
                        <Col>
                            <img src='./assets/images/Toic650.jpg' width='400px'/>
                        </Col>
                        <Col>
                            <h1 className='headerOfPage'>Toeic 550+</h1>
                        </Col>
                    </Row>
                </Container> */}
            </div>
        );
    }
}