import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
export class CourseDetail extends Component {
    static displayName = "Khóa học"

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                        <a href={this.props.url} class="navbar-brand">
                        <img className='courseDetail' src={this.props.imagePath} />
                        </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className='courseDetailDiv'>
                            <a className='courseDetailLink' href={this.props.url}>{this.props.name}</a>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}