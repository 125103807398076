import React, { Component } from 'react';
import { SlideShowSchedule } from './SlideShowSchedule';
import Table from 'react-bootstrap/Table';

function Item(props) {
    // Correct! There is no need to specify the key here:
    //return <li>{props.value}</li>;'
    const href = '../assets/files/' + props.filename;
    return                                     <tr>
    <td>{props.filename}</td>
    <td>
        
        <a href={href} download={props.filename} target='_blank'>
            <button type="button" class="btn btn-success btn-block">Download document</button>
        </a>
    </td>
    </tr>
}

export class Document extends Component {
    static displayName = "Lịch học";
    numbers = [1, 2, 3, 4, 5];

    constructor(props) {
        super(props);
        this.state = {documentRowInfo: null};
    }

    async getdata() {
        var listItems = [];

        return await fetch('../assets/files/DocumentName.txt')
        .then(response => response.text())
        .then(fileText => {
            const lines = fileText.split('\n');
            // do something with the lines here...
            lines.map((line) => 
                listItems.push(line)
            );
            
        }).then((X) => {return listItems});
    }    

    componentDidMount() {
        var listItems = [];
        this.getdata().then((x) => {
                        console.log(x);
                        var listFileName = x.map((filename, index) =>
                                    <Item  key={index} filename = {filename}></Item>
                                );
                        this.setState({documentRowInfo : listFileName})
                    }
        );
    }    

    render() {
         return (
            <div>
                <SlideShowSchedule />
                <br />
                <div class="separator">
                    <div class="line"></div>
                    <h1 className='headerOfPage'>Tài liệu</h1>
                    <div class="line"></div>
                    </div>
                <br />
                <Table>
                    <thead>
                        <tr>
                        <th className='headerOfTable' id='documentName' >Tên file</th>
                        <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.documentRowInfo}
                    </tbody>
                </Table>
                <br />
            </div>
        );
    }
}